<template id="rosacoach">
  <div>
    <b-row v-for="(items, gruppo) in players" :key="gruppo">
      <b-col>
        <b-row
          align-v="center"
          class="mt-2 mb-2"
          style="min-height: 40px; background: rgba(23, 164, 184, 0.5)"
          ><b-col cols="1" style="font-size: 0.85rem"
            ><strong>{{ gruppi[gruppo] }}</strong></b-col
          >
          <b-col
            v-for="(title, key) in principles_title"
            :key="key"
            class="text-center"
          >
            <strong>{{ title.substr(0, 3) }}</strong>
          </b-col>
          <b-col class="text-center"> <strong>MICRO</strong></b-col>
          <b-col class="text-center"><strong>MACRO</strong></b-col>
        </b-row>
        <b-row
          v-for="player in items"
          :key="player.id"
          class="mb-1"
          :style="!players_ids.includes(player.id) ? 'opacity:0.4' : ''"
        >
          <b-col cols="1" class="text-center">
            <b-row align-v="center">
              <b-col cols="2" class="p-0">
                <b-form-checkbox
                  name="players_sel"
                  v-model="players_ids"
                  :value="player.id"
                >
                </b-form-checkbox>
              </b-col>
              <b-col class="p-0">
                <b-img
                  :src="
                    player && player.giocatore
                      ? player.giocatore.avatar_url
                      : '/assets/images/profile.png'
                  "
                  height="40"
                ></b-img>
                <br />
                <div style="font-size: 0.8rem">
                  {{
                    player.giocatore
                      ? player.giocatore.cognome
                      : player.lastName
                  }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-for="(index, key) in player.optaIndex
              ? JSON.parse(player.optaIndex.principles_index)
              : []"
            :key="key"
            class="p-0 text-center"
          >
            <Progress
              v-if="
                (player.ruolo_id == 6 && key != 12) ||
                (player.ruolo_id != 6 && key != 11)
              "
              :strokeColor="
                bg_opta[
                  JSON.parse(player.optaIndex.principles_index_color_rank)[key]
                ]
              "
              :value="index"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
          <b-col class="p-0 text-center" v-if="player.optaIndex">
            <Progress
              :strokeColor="bg_opta[player.optaIndex.micro_index_color_rank]"
              :value="player.optaIndex.micro_index"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
          <b-col class="p-0 text-center" v-if="player.optaIndex">
            <Progress
              :strokeColor="bg_opta[player.optaIndex.macro_index_color_rank]"
              :value="player.optaIndex.macro_index"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-2" align-v="center" v-if="show_add">
          <b-col cols="1"
            ><b-button
              size="sm"
              variant="primary"
              @click="openSearchPlayer(gruppo)"
              ><b-icon icon="plus"></b-icon></b-button
          ></b-col>
        </b-row>
        <b-row
          class="mt-3 mb-2"
          align-v="center"
          style="background: rgba(255, 255, 255, 0.1)"
        >
          <b-col cols="1"><strong>AVG</strong></b-col>
          <b-col
            v-for="(title, key) in principles_title"
            :key="key"
            class="p-0 text-center"
          >
            <Progress
              v-if="(gruppo == 0 && key != 12) || (gruppo != 0 && key != 11)"
              :strokeColor="getColorMediaPrinciple(gruppo, key)"
              :value="getMediaPrinciple(gruppo, key)"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
          <b-col class="p-0 text-center">
            <Progress
              :strokeColor="getColorMediaMicro(gruppo)"
              :value="getMediaMicro(gruppo)"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
          <b-col class="p-0 text-center">
            <Progress
              :strokeColor="getColorMediaMacro(gruppo)"
              :value="getMediaMacro(gruppo)"
              :radius="28"
              :strokeWidth="4"
              :transitionDuration="0"
            ></Progress>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <b-row
      align-v="center"
      style="min-height: 40px; background: rgba(23, 164, 184, 0.5)"
    >
      <b-col cols="1"><strong>TEAM</strong></b-col>
      <b-col
        v-for="(title, key) in principles_title"
        :key="key"
        class="text-center"
      >
        <strong>{{ title.substr(0, 3) }}</strong>
      </b-col>
      <b-col class="text-center"> <strong>MICRO</strong></b-col>
      <b-col class="text-center"><strong>MACRO</strong></b-col>
    </b-row>
    <b-row
      class="mt-3 mb-2"
      align-v="center"
      style="background: rgba(255, 255, 255, 0.1)"
    >
      <b-col cols="1"><strong>AVG</strong></b-col>
      <b-col
        v-for="(title, key) in principles_title"
        :key="key"
        class="p-0 text-center"
      >
        <Progress
          :strokeColor="getTotColorMediaPrinciple(key)"
          :value="getTotMediaPrinciple(key)"
          :radius="28"
          :strokeWidth="4"
          :transitionDuration="0"
        ></Progress>
      </b-col>
      <b-col class="p-0 text-center">
        <Progress
          :strokeColor="getTotColorMediaMicro()"
          :value="getTotMediaMicro()"
          :radius="28"
          :strokeWidth="4"
          :transitionDuration="0"
        ></Progress>
      </b-col>
      <b-col class="p-0 text-center">
        <Progress
          :strokeColor="getTotColorMediaMacro()"
          :value="getTotMediaMacro()"
          :radius="28"
          :strokeWidth="4"
          :transitionDuration="0"
        ></Progress>
      </b-col>
    </b-row>
    <b-modal
      id="addPlayerModal"
      ref="addPlayerModal"
      :title="'Add player to ' + gruppi[gruppo]"
      ok-only
      ok-title="Close"
    >
      <b-row class="my-2">
        <b-col>
          <b-input-group>
            <b-form-input
              type="text"
              v-model="search_name"
              placeholder="Search opta player"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="searchOptaPlayer"
                ><b-icon icon="search"></b-icon
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div v-if="is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px" />
        </h4>
      </div>
      <p v-if="!start_search_opta && !is_search && !opta_players.length">
        No player found!
      </p>
      <div v-if="!start_search_opta && !is_search && opta_players.length">
        <p>{{ opta_players.length }} players found!</p>
        <hr />
        <div style="height: 300px; overflow-y: scroll; overflow-x: hidden">
          <div class="row">
            <div
              v-for="opta_player in opta_players"
              :key="opta_player.id"
              class="col-6"
              style="border-bottom: 1px solid #343a40"
            >
              <b-row align-v="center" class="py-2">
                <b-col
                  cols="12"
                  style="font-size: 0.85rem; cursor: pointer"
                  @click="addOptaPlayer(opta_player.id)"
                >
                  <h6 style="display: inline">
                    {{ opta_player.lastName }} {{ opta_player.firstName }}
                  </h6>
                  <br />
                  <span class="text-muted">{{ opta_player.age }} years old</span
                  ><br />
                  <span class="text-muted">{{
                    opta_player.ruolo ? opta_player.ruolo.ruolo : ""
                  }}</span
                  ><br />
                  <span
                    class="text-muted"
                    v-if="opta_player.last_stat && opta_player.last_stat.team"
                  >
                    {{ opta_player.last_stat.team.name }} </span
                  ><br />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Progress from "easy-circular-progress";
import loading from "@/assets/images/loading.gif";
export default {
  props: {
    rosa_gruppi_ruoli: {
      type: Object,
      required: true,
    },
    show_add: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      players: [],
      players_ids: [],
      gruppi: {
        0: "GOALKEEPER",
        1: "CENTRAL DEFENDER",
        2: "BACK WINGER",
        3: "CENTRAL MIDFIELDER",
        4: "ATTACKING MIDFIELDER",
        5: "CENTRAL FORWARD",
        6: "WINGER",
      },
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      gruppo: -1,
      opta_players: [],
      search_name: null,
      start_search_opta: true,
      is_search: false,
      loading,
    };
  },

  components: {
    Progress,
  },

  mounted: function () {
    this.players = Object.assign({}, this.rosa_gruppi_ruoli);
    for (var gruppo in this.players) {
      for (var i in this.players[gruppo]) {
        var player = this.players[gruppo][i];
        if (player.optaIndex && player.optaIndex.minutes > 600) {
          this.players_ids.push(player.id);
        }
      }
    }
  },

  computed: {},

  methods: {
    getMediaPrinciple(gruppo, principio) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          var principles = JSON.parse(player.optaIndex.principles_index);
          value += principles[principio];
          count++;
        }
      }

      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getColorMediaPrinciple(gruppo, principio) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          var principles = JSON.parse(
            player.optaIndex.principles_index_color_rank
          );
          value += principles[principio];
          count++;
        }
      }

      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    getTotMediaPrinciple(principio) {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (
            player.optaIndex &&
            this.players_ids.includes(player.id) &&
            ((principio != 12 && player.ruolo_id == 6) ||
              (principio != 11 && player.ruolo_id != 6))
          ) {
            var principles = JSON.parse(player.optaIndex.principles_index);
            value += principles[principio];
            count++;
          }
        }
      }
      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getTotColorMediaPrinciple(principio) {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (
            player.optaIndex &&
            this.players_ids.includes(player.id) &&
            ((principio != 12 && player.ruolo_id == 6) ||
              (principio != 11 && player.ruolo_id != 6))
          ) {
            var principles = JSON.parse(
              player.optaIndex.principles_index_color_rank
            );
            value += principles[principio];
            count++;
          }
        }
      }
      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    getMediaMicro(gruppo) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          value += player.optaIndex.micro_index;
          count++;
        }
      }
      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getColorMediaMicro(gruppo) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          value += player.optaIndex.micro_index_color_rank;
          count++;
        }
      }

      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    getMediaMacro(gruppo) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          value += player.optaIndex.macro_index;
          count++;
        }
      }
      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getColorMediaMacro(gruppo) {
      var value = 0;
      var count = 0;
      var players = this.players[gruppo];
      for (var i in players) {
        var player = players[i];
        if (player.optaIndex && this.players_ids.includes(player.id)) {
          value += player.optaIndex.macro_index_color_rank;
          count++;
        }
      }

      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    getTotMediaMicro() {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (player.optaIndex && this.players_ids.includes(player.id)) {
            value += player.optaIndex.micro_index;
            count++;
          }
        }
      }
      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getTotColorMediaMicro() {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (player.optaIndex && this.players_ids.includes(player.id)) {
            value += player.optaIndex.micro_index_color_rank;
            count++;
          }
        }
      }

      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    getTotMediaMacro() {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (player.optaIndex && this.players_ids.includes(player.id)) {
            value += player.optaIndex.macro_index;
            count++;
          }
        }
      }
      value = Math.round((value / count) * 100) / 100;
      return value;
    },

    getTotColorMediaMacro() {
      var value = 0;
      var count = 0;
      for (var gruppo in this.players) {
        var players = this.players[gruppo];
        for (var i in players) {
          var player = players[i];
          if (player.optaIndex && this.players_ids.includes(player.id)) {
            value += player.optaIndex.macro_index_color_rank;
            count++;
          }
        }
      }
      value = Math.round(value / count);
      return this.bg_opta[value];
    },

    openSearchPlayer(group) {
      this.search_name = null;
      this.gruppo = group;
      this.opta_players = [];
      this.$refs.addPlayerModal.show();
    },

    searchOptaPlayer: function () {
      if (this.search_name != "") {
        this.opta_players = [];
        this.is_search = true;
        this.$http
          .get("/opta/search/player/" + this.search_name)
          .then((response) => {
            this.opta_players = response.data;
            this.start_search_opta = false;
            this.is_search = false;
          });
      }
    },

    addOptaPlayer: function (id) {
      this.$refs.addPlayerModal.hide();
      this.$http.get("/player/with/index/" + id).then((response) => {
        var player = response.data;
        this.players[this.gruppo].push(player);
        if (player.optaIndex && player.optaIndex.minutes > 600) {
          this.players_ids.push(player.id);
        }
      });
    },
  },

  filters: {},
};
</script>
