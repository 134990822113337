<template id="teams">
  <b-container fluid>
    <b-card>
      <b-row align-v="center">
        <b-col cols="1">
          <b-img
            v-if="filters.coach_team"
            :src="
              filters.coach_team.squadra
                ? filters.coach_team.squadra.logo_url
                : '/assets/images/team.png'
            "
            height="80"
          />
        </b-col>
        <b-col>
          <h4 v-if="filters.coach_team">
            {{ filters.coach_team.name }}
          </h4>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="waiting_rosa" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting_rosa && rosa_gruppi_ruoli">
      <b-card style="background: transparent">
        <rosa-coach
          :rosa_gruppi_ruoli="rosa_gruppi_ruoli"
          :show_add="true"
        ></rosa-coach>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import loadingImg from "@/assets/images/loading.gif";
//import Progress from "easy-circular-progress";
import RosaCoach from "@/components/RosaCoach.vue";
export default {
  components: {
    //  Progress,
    RosaCoach,
  },

  data: function () {
    return {
      rosa_gruppi_ruoli: null,
      waiting: false,
      waiting_rosa: false,
      filters: {
        from: this.getInitialFromDate(),
        to: this.getInitialToDate(),
        coach_team: null,
      },
      loadingImg,
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
    };
  },

  created: function () {
    this.getTeam();
  },

  methods: {
    getTeam() {
      this.$http
        .get("/opta/team/" + this.$route.params.id)
        .then((response) => {
          this.filters.coach_team = response.data;
          this.getStatsRosa();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStatsRosa() {
      this.waiting_rosa = true;
      this.$http
        .get("/scouting/stats/rosa", {
          params: this.filters,
        })
        .then((response) => {
          this.rosa_gruppi_ruoli = response.data.rosa_gruppi_ruoli;
          this.waiting_rosa = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getInitialFromDate() {
      let month = moment("08-05", "MM-DD");
      let lastMonth = month < moment() ? month : month.subtract(1, "years");
      return lastMonth.format("YYYY-MM-DD");
    },
    getInitialToDate() {
      return moment().format("YYYY-MM-DD");
    },
  },

  watch: {},

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
